import React from "react";
import {Accordion,Container,Row, Col} from "react-bootstrap";
import { AnimationOnScroll } from 'react-animation-on-scroll';
interface Faq{
    q: string;
    a: string;
}

const FAQ = () => {
    const Faqs : Faq[] = [
        {
            q: 'How much do TheShitz cost?',
            a: 'The Shitz will be available to mint for 1 SOL each.'
        },
        {
            q: 'Is there a limit on how many TheShitz I can mint?',
            a:'You can mint as many Shitz as you want with a maximum of 10 per transaction.'
        },
        {
            q: 'When can I mint?',
            a:'Stealth Launch'
        },
        {
            q: 'Where can I mint?',
            a:'Minting will ONLY be accessible at https://theshitz.io – double-check the URL to ensure it’s correct before proceeding with minting!'
        },
        {
            q: 'Which Solana Wallets will be supported?',
            a: 'You’ll need a Solana wallet to purchase and manage your Shitz NFT collection, we recommend using one of the following wallets: <a href ="https://phantom.app/" rel="noreferrer" target="_blank">https://phantom.app/</a> OR <a href = "https://www.sollet.io/" rel="noreferrer" target="_blank">https://www.sollet.io/</a>'
        },
        {
            q: 'How many Shitz will be reserved for Giveaways and Marketing?',
            a:'We will reserve 100 Shitz NFTs for giveaways and prominent influencers to build the community and raise awareness leading-up to the mint.'
        },
        {
            q: 'How many attributes do The Shitz have?',
            a: 'There are over 200 attributes covering backgrounds, body hues, faces, hair, outfits, hand gestures, and accessories.'
        },
        {
            q: 'Will The Shitz have a Rarity System?',
            a:'Yes.  As soon as all Shitz have been minted, we’ll release a chart to show the variation in rarity from common to legendary features, including everything from dark brown Shitz to Shitz of made of gold!'
        },
        {
            q: 'Will I be able to trade The Shitz on a secondary market?',
            a:'Our secondary market will be accessible via Solanart, DigitalEyes, and Solsea NFT marketplaces immediately after launch. Our Discord community will also have a marketplace channel reserved for trading.'
        },
        {
            q: 'Do I own the rights to my Shitz NFT?',
            a:'As the owner of a Shitz NFT, you’ll have full commercial rights to use, sell, and profit in accordance with The Shitz Purchase and Licensing Agreement located in the Documents section of this website.'
        },
        {
            q: 'Will there be Resale Royalties?',
            a:'A 5% commission will be applied to all resales. We will be using these funds for future research and development of The Shitz.'
        }
    ];
    
    return (
        <div id="faqscreen">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2 className="text-center">FAQs</h2>
                        {
                            Faqs.map((item,idx) => 
                                    <div key={"faq-"+idx} className="m-4">
                                        <AnimationOnScroll animateIn='animate__fadeInUp'>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>{item.q}</Accordion.Header>
                                                    <Accordion.Body dangerouslySetInnerHTML={{__html: item.a}}/>
                                                </Accordion.Item>
                                            </Accordion>
                                        </AnimationOnScroll>
                                    </div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FAQ;