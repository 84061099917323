import React from "react";

import Home from "../Components/Home";
import MeetTheTeam from "../Components/MeetTheTeam";
import FAQ from "../Components/FAQ";
import HowManyShitz from "../Components/HowManyShitz";
import WhyBecomeShitz from '../Components/WhyBecomeShitz';
import WhoTheShitz from '../Components/WhoTheShitz';
import Roadmap1 from '../Components/Roadmap1';
import Roadmap2 from '../Components/Roadmap2';
import Community from '../Components/Community';
import Path10 from '../images/Path10.svg';
const Landing = () => {
    return (
        <>
            
            <Home />
            <WhoTheShitz />
            <div id="why-and-how">
                <WhyBecomeShitz />
                <HowManyShitz />
            </div>
            <div id="roadmap"
            style={{
                backgroundColor:'#FFC938',
                backgroundImage:`url(${Path10})`,
                backgroundPosition:'center',
                backgroundSize:'auto 100%',
                backgroundRepeat:'repeat-y'
            }}
            >
                <Roadmap1 />
                <Roadmap2 />
            </div>
            <MeetTheTeam />
            <FAQ />
            <Community />
        </>
    );
}

export default Landing;