import React,{useState, useEffect} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
//import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {Container, Row,Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import { SocialIcon } from 'react-social-icons';
import ToTop from '../images/Component14-1.svg';
import Logo from '../images/logo.jpg';
import { Link } from "react-router-dom";

/*
const Content: FC = () => {
    return <WalletMultiButton className="custom_btn"/>;
  };
*/
const Navigation = () => {
    const [fgColorTikTok,setFgColorTikTok] = useState("#FFF");
    const [fgColorTwitter,setFgColorTwitter] = useState("#FFF");
    const [fgColorDiscord,setFgColorDiscord] = useState("#FFF");
    const [yLoc, setYLoc] = useState(window.scrollY);
    const handleNavigation = (e:any) => {
        const window = e.currentTarget;
        setYLoc(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));
      
        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
           window.removeEventListener("scroll", (e) => handleNavigation(e));
           setYLoc(window.scrollY);
        };
    }, [yLoc]);
    const location = useLocation();
    const hoverSITikTok = () => {
        setFgColorTikTok("#F97829");
    }
    const hoverSITwitter = () => {
        setFgColorTwitter("#F97829");
    }
    const hoverSIDiscord = () => {
        setFgColorDiscord("#F97829");
    }
    const hoverOutSI = () => {
        setFgColorTikTok("#FFF");
        setFgColorTwitter("#FFF");
        setFgColorDiscord("#FFF");
    }
    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Navbar collapseOnSelect 
                        fixed="top" 
                        expand="lg" 
                        bg="dark" 
                        variant="dark" 
                        style={{height:'4em'}}
                        >
                        <Container>
                            <Link to="/" className="navbar-brand">
                                <img src={Logo} 
                                className="d-inline-block align-top"
                                width='100'
                                height='50'
                                alt="The Shitz"/>
                            </Link>
                           
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    { !['/mint','/learn-setup-phantom-wallet'].includes(location.pathname) ?
                                        <>
                                            
                                                <Nav.Link href="#who-are-the-shitz">Who are The Shitz?</Nav.Link>
                                                <Nav.Link href="#why-become-a-shitzholder">Why Become a Shitzholder?</Nav.Link>
                                                <Nav.Link href="#howmanyscreen">Varieties of Shitz</Nav.Link>
                                                <Nav.Link href="#roadmapscreen1">Roadmap</Nav.Link>
                                                <NavDropdown title="" id="collasible-nav-dropdown">
                                                    <NavDropdown.Item href="#teamscreen">Meet the Team</NavDropdown.Item>
                                                    <NavDropdown.Item href="#faqscreen">FAQs</NavDropdown.Item>
                                                    <NavDropdown.Item href="#community">Community</NavDropdown.Item>
                                                    <NavDropdown.Item 
                                                    href="https://docs.google.com/document/d/1kNfpzDgmZmUHYvZp8rbY_c7R_iQuJ8Vp/edit?usp=sharing&ouid=102623134585311546756&rtpof=true&sd=true">
                                                        Document
                                                    </NavDropdown.Item>
                                                </NavDropdown>
                                            
                                        </> : <></> 
                                    }
                                </Nav>
                                <div className="d-flex social-connect">
                                    <div className="p-1"> 
                                        { 
                                          /*
                                            location.pathname === '/mint' ? 
                                            <Content /> : <></>
                                            */
                                        }
                                    </div>
                                    <div className="p-1">                
                                            <SocialIcon 
                                            network="discord"
                                            url="https://discord.gg/RycR7D7qcr"
                                            style={{width:35,height:35}}
                                            fgColor={fgColorDiscord}
                                            onMouseEnter={hoverSIDiscord}
                                            onMouseLeave={hoverOutSI}
                                            bgColor="#000"
                                            className="social_hover"
                                        />
                                    </div>
                                    <div className="p-1"> 
                                        <SocialIcon 
                                            network="tiktok"
                                            url="https://tiktok.com/@biggyshitz"
                                            style={{width:35,height:35}}
                                            fgColor={fgColorTikTok}
                                            onMouseEnter={hoverSITikTok}
                                            onMouseLeave={hoverOutSI}
                                            bgColor="#000"
                                            className="social_hover"
                                        /> 
                                    </div>
                                    <div className="p-1">
                                        <SocialIcon 
                                            url="https://twitter.com/BiggyShitz"
                                            style={{width:35,height:35}}
                                            fgColor={fgColorTwitter}
                                            onMouseEnter={hoverSITwitter}
                                            onMouseLeave={hoverOutSI}
                                            bgColor="#000"
                                            className="social_hover"
                                        />
                                    </div>
                                </div>
                            </Navbar.Collapse>
                            
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
            { yLoc > 600 ?

                <div className="to-top" onClick={ () => {
                    window.scrollTo(0, 0)
                }
                }>
                    <img src={ToTop} alt="to top" className="img-fluid" />
                </div> : <></>
            }
            
            </>
    );
}


export default Navigation;

/*
<Dropdown>
    <Dropdown.Toggle id="dropdownLinks1" variant="secondary">
        More links
    </Dropdown.Toggle>
    <Dropdown.Menu variant="dark">
        <Dropdown.Item href="/reactdemo/page1">Page 1</Dropdown.Item>
        <Dropdown.Item href="/reactdemo/page2">Page 2</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
*/
