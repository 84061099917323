import '@solana/wallet-adapter-react-ui/styles.css';
import "./css/bootstrap.min.css";
import "./css/timeline.css";
import "animate.css/animate.min.css";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/jessica.css";
import '@fortawesome/fontawesome-svg-core/styles.css'

import { useMemo } from "react";
import * as anchor from "@project-serum/anchor";
import Footer from "./Components/Footer";
import Landing from "./Pages/Landing";
import Mint from "./Pages/Mint";
import SetUpPhantom from "./Pages/SetUpPhantom";
import Navigation from './Components/Navigation';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@mui/material";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import {
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-sollet";
import { 
  createSolanaClient,
  Languages,
} from "@blowfishxyz/api-client/v20230605";

const blowfish = createSolanaClient({
  basePath: 'https://theshitz.io:8080',
  chainNetwork: "mainnet",
  // Optional: It's highly encouraged to use a proxy server to not expose your API key on the client (see: https://docs.blowfish.xyz/docs/wallet-integration-guide#optional-proxy-server)
  apiKey: undefined,
  // Optional
  language: Languages.En,
});

if(window.location.hostname !== 'localhost') {
  ( async() => {
    const shitzscan = await blowfish.scanDomains(["https://theshitz.io"]);
    console.log(shitzscan);
  })();
}
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});



const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_WS ?? anchor.web3.clusterApiUrl("devnet");
const HTTP_CONNECTION = process.env.REACT_APP_SOLANA_RPC_HOST as string;
const connection = new anchor.web3.Connection(HTTP_CONNECTION, {wsEndpoint:rpcHost});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );
  const router = createBrowserRouter([
    {
      path: "/",
      element: <><Navigation /><Landing /></>,
    },
    {
      path: "/mint",
      element: <><Navigation /><Mint
                candyMachineId={candyMachineId}
                connection={connection}
                txTimeout={DEFAULT_TIMEOUT}
                rpcHost={HTTP_CONNECTION}
                network={network}
                error={error}
              /></>,
    },
    {
      path: "/learn-setup-phantom-wallet",
      element: <><Navigation /><SetUpPhantom /></>,
    },
  ]);
  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <RouterProvider router={router} />
            <Footer />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;