import axios from 'axios';

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL!,
    withCredentials: true
});

export const isWhitelisted = async (account : string): Promise<any[]> =>{
    let whitelisted: any[] = [];
    await httpClient.post('/is_whitelisted', {
        account: account
    }).then((response) => {
        whitelisted = response.data as unknown as any[];
    }).catch((error)=>{
        console.log(error);
    });
    return whitelisted;
}

export const addToWhitelist = async(account : string): Promise<any[]> => {
    let whitelisted: any[] = [];
    await httpClient.post('/add_to_whitelist', {
        account: account
    }).then((response) => {
        whitelisted = response.data as unknown as any[];
    }).catch((error)=>{
        console.log(error);
    });
    return whitelisted;
}
export const scanTheShitz = async(account : string): Promise<any[]> =>{
    let result: any[] = [];
    await httpClient.post('/scan_the_shitz', {
        account: account
    }).then((response) => {
        result = response.data as unknown as any[];
    }).catch((error)=>{
        console.log(error);
    });
    return result;
}
export const minted = async(account: string, tx: (string | undefined)[]): Promise<any[]> => {
    let result: any[] = [];
    
    await httpClient.post('/minted', {
        account: account,
        tx: tx
    }).then((response) => {
        result = response.data as unknown as any[];
    }).catch((error)=>{
        console.log(error);
    });
    
    return result;
}
export const shitz = async(account:string) : Promise<any> => {
    let result: any = null;
    await httpClient.post('/shitz', {
        account: account
    }).then((response) => {
        result = response.data as unknown as any;
    }).catch((error)=>{
        console.log(error);
    });
    return result;
}

export const rename = async(mint_address: string, new_name:string) : Promise<any> => {
    let result: any = null;
    await httpClient.post('/rename', {
        mint_address: mint_address,
        new_name: new_name
    }).then((response) => {
        result = response.data as unknown as any;
    }).catch((error)=>{
        console.log(error);
    });
    return result;
}
export const stake = async(account: string, mint: string): Promise<any> => {
    let result: any = null;
    await httpClient.post('/stake', {
        account: account,
        mint: mint
    }).then((response) => {
        result = response.data as unknown as any;
    }).catch((error)=>{
        console.log(error);
    });
    return result;
}
export default httpClient;
