import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SelectBrowser from '../images/select-browser.png';
import AddToChrome from '../images/add-to-chrome.png';
import CreateWallet from '../images/create-a-new-wallet.png';
import CreatePassword from '../images/create-a-password.png';
import SeedPhrase from '../images/secret-recovery-phrase.png';
import CopyAddress from '../images/copy-address.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const SetUpPhantom = () => {
    return (
        <>
            
            <div style={{height:100}}>&nbsp;</div>
            <Container>
                <Row>
                    <Col xs={12}>
                       <h1>Getting set up!</h1>
                       <h5 style={{color:'black'}}>Learn how to set up your Phantom wallet</h5>
                       <p>1.) Go to &nbsp; 
                           <a href="https://phantom.app/" target="_blank" rel="noreferrer">
                           https://phantom.app/
                           </a>
                       &nbsp; and click "Download". Click your preferred browser.</p>
                       <img src={SelectBrowser} alt="select browser" className="img-fluid mb-4" />
                       <p>2.) Click "Add to Chrome" or whichever browser you use.</p>
                        <img src={AddToChrome} alt="add to chrome" className="img-fluid mb-4" />
                       <p>3.) Click on "Create a new wallet."</p>
                       <img src={CreateWallet} alt="create a new wallet" className="img-fluid mb-4" />
                       <p>4.) Create a password that you will use to unlock your wallet.</p>
                       <img src={CreatePassword} alt="create a password" className="img-fluid mb-4" />
                        <p>5.) Hover your mouse cursor on the eye icon <FontAwesomeIcon icon={faEyeSlash} /> to view your secret recovery phrase and make sure to WRITE DOWN your seed phrase. 
                            Do not send it to yourself through email and physically write it down on 3-4 sheets of paper. 
                            Take those papers and put them in very safe places. 
                            Follow the remaining prompts.
                        </p>
                        <h6 style={{color:'black'}}>** Your seed phrase is the most important phrase you own. 
                            This is an all-access pass to your Web3 Wallet. 
                            Never give it to ANYONE. ** 
                        </h6>
                        <img src={SeedPhrase} alt="secret recovery phrase" className="img-fluid mb-4" />
                        <p>6.)  Now that you have written down your seed phrase and set up your wallet. 
                            You will be looking at your Phantom wallet dashboard. 
                            This dashboard allows you to find your wallet address and send/receive SOL and other Solana-based cryptos.
                            You can click "Wallet 1" to copy your SOLANA address. 
                        </p>
                        <img src={CopyAddress} alt="copy solana address" className="img-fluid mb-4" />
                        
                        <p>7.) Now you're ready to enter Web3!</p>
                     </Col>
                </Row>
            </Container>
        </>
    );
}

export default SetUpPhantom;