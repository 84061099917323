import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import BrownPoop from '../images/brown-poop.png';
import DarkGreenBrownPoop from '../images/dark-green-brown-poop.png';
import GreenBrownPoop from '../images/green-brown-poop.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
const Roadmap1 = () => {
    return (
        <div id="roadmapscreen1">
            <Container>
                <Row>
                    <Col sm={{span:6,offset:6}}>
                        <h2 className="mt-2 text-end">Roadmap Milestones</h2>
                    </Col>
                </Row>
                <Row>
                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <Col lg={6} className="text-end poop-container poop-c1"
                            style={{
                                backgroundImage:`url(${BrownPoop})`
                            }}
                        >
                            <div className="poop">
                                <div className="h6">START</div>
                                Stealth Launch
                            </div>
                        </Col>
                    </AnimationOnScroll>
                </Row>
                <Row>
                    <AnimationOnScroll animateIn="animate__fadeInRight">
                        <Col lg={{span:6,offset:6}}
                            style={{
                                backgroundImage:`url(${DarkGreenBrownPoop})`
                            }}
                            className="poop-container poop-c2"
                        >
                            <div className="poop">
                                    <div className="h6">10% SOLD!</div>
                                    We fire up the Shitz meme generator on our website and really get the party started.
                            </div>
                        </Col>
                    </AnimationOnScroll>
                </Row>
                <Row>
                    <AnimationOnScroll animateIn='animate__fadeInLeft'>
                        <Col lg={6}
                            style={{
                                backgroundImage:`url(${GreenBrownPoop})`
                            }}
                            className="poop-container poop-c3 text-end"
                        >
                            <div className="poop">
                                <div className="h6">25% SOLD!</div>
                                Donate 10% of all sales to digestive disease charities as proposed and voted by the Shitzholders
                            </div>
                        </Col>
                    </AnimationOnScroll>
                </Row>
            </Container>
        </div>
    );
}

export default Roadmap1;