import React,{useState} from "react";

import {Container, 
    Row, 
    Col, 
    Card, 
    Dropdown,
    Modal, 
    Button,
    Form,
    Badge
} from 'react-bootstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faSpinner,faEye, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
//import {rename,stake as setStake} from '../api';
import {rename} from '../api';
import { AlertState } from '../utils';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

//import {stake} from '../staking';

const NFTs = (props: any) => {
    const [show, setShow] = useState(false);
    const [name,setName] = useState<string>();
    const [mintAddress,setMintAddress] = useState<string>();
    const [renaming, setRenaming] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (name:string,mint_adress:string) => {
        setName(name);
        setMintAddress(mint_adress);
        setShow(true);
    };
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: '',
        severity: undefined,
    });
    const assetLink = (hash:string) => {
        return `${process.env.REACT_APP_ASSETS_URL!}${hash}`;
    }
    const solScanLink = (address:string) => {
        return `https://solscan.io/token/${address}?cluster=${process.env.REACT_APP_SOLANA_NETWORK!}`;
    }
    const handleRename = async() => {
        setRenaming(true);
        const result = await rename(mintAddress!, name!);
        console.log(result);
        setRenaming(false);
        setShow(false);
        
        setAlertState({
            open: true,
            message: result.message,
            severity:(result.error ? 'error' : 'success')
        });
        props.populateShitz(props.aWallet);
    }
    /*
    const handleStake = async (mint: string) => {
        console.log('stake!?');
        try{
            await stake(props.aWallet, mint, props.connection);
            await setStake(props.aWallet.publicKey.toBase58(),mint);
            props.populateShitz(props.aWallet);
            setAlertState({
                open: true,
                message: `Success!`,
                severity:( 'success')
            });
        }catch(e:unknown){
            let  error = 'Error occured.';
            if (typeof e === "string") {
                error = e.toUpperCase() // works, `e` narrowed to string
            } else if (e instanceof Error) {
                error = e.message // works, `e` narrowed to Error
            }
            setAlertState({
                open: true,
                message: error,
                severity:( 'error')
            });
        }
    }
    */
    return (
        <>
            <Container className="mt-4">
                <Row>
                    {
                        props.shitz.map( (shit:any,idx:number) =>
                            <Col lg={4} md={6} sm={12} key={"item-"+idx}>
                                <AnimationOnScroll 
                                    animateIn="animate__fadeInDown">
                                    <Card className="mb-2 mt-2" style={{position:'relative'}}>
                                    <Dropdown>
                                            <Dropdown.Toggle variant="secondary" className="nft-menu" id={"dropdown-basic-"+idx}>
                                                <FontAwesomeIcon icon={faEllipsis}/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="nft-dropdown-popup">
                                                <Dropdown.Item href={solScanLink(shit.mint_address)} target="_blank">
                                                    View on Solscan
                                                </Dropdown.Item>
                                                {!shit.metadata.renamed ? 
                                                    <Dropdown.Item 
                                                    href="#"
                                                    onClick={() => handleShow(shit.metadata.name, shit.mint_address)}
                                                    >
                                                        Rename
                                                    </Dropdown.Item>
                                                    : <></>
                                                }
                                                {
                                                    /*
                                                        {!shit.staked ?
                                                            <Dropdown.Item 
                                                            href="#"
                                                            onClick={ () => handleStake(shit.mint_address)}
                                                            >
                                                                Stake
                                                            </Dropdown.Item>
                                                            : <>
                                                                <Dropdown.Item 
                                                                href="#"
                                                                >
                                                                    Unstake
                                                                </Dropdown.Item>
                                                                <Dropdown.Item 
                                                                href="#"
                                                                >
                                                                    Stake withdraw
                                                                </Dropdown.Item>
                                                            </>
                                                        }
                                                    */
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="nft-views">
                                            <Badge pill bg="secondary">
                                                {shit.analytics_count} <FontAwesomeIcon icon={faEye} />
                                            </Badge>
                                        </div>
                                        <Card.Img 
                                        variant="top" 
                                        src={assetLink(shit.hash)}
                                        />
                                        <Card.Body>
                                            <Card.Title>
                                                {shit.metadata.name}
                                                <div className="float-end">
                                                    { shit.metadata.gender === 'male' ?
                                                        <FontAwesomeIcon icon={faMars} />
                                                        : <FontAwesomeIcon icon={faVenus} />
                                                    }
                                                </div>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </AnimationOnScroll>
                            </Col>
                        )
                    }
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>RENAME</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Form.Group className="mb-3" controlId="formRename">
                        <Form.Label>Enter name</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Enter name"
                        maxLength={32}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                         />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleRename()} disabled={renaming}>
                    { renaming ? 
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                        : <></>
                    }
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({ ...alertState, open: false })}
            >
                <Alert
                onClose={() => setAlertState({ ...alertState, open: false })}
                severity={alertState.severity}
                >
                {alertState.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default NFTs;